export const socialsData = {
  github: "https://github.com/Sidney-Okine",
  facebook: "https://www.facebook.com/okinesidney",
  linkedIn: "https://www.linkedin.com/in/okinesidney",
  instagram: "https://www.instagram.com/sidney.okine/",
  codepen: "https://codepen.io/",
  twitter: "https://twitter.com/okine_sidney",
  reddit: "https://www.reddit.com/user/",
  blogger: "https://www.blogger.com/",
  medium: "https://medium.com/@",
  stackOverflow:
    "https://stackoverflow.com/users/11295660/sidney-okine?tab=profile",
  gitlab: "https://gitlab.com/",
  youtube: "https://youtube.com/channel/UChd59VXHxPpbldYHdloiqhw",
};
