export const experienceData = [
  {
    id: 9,
    company: "Private Medical Team",
    jobtitle: "EKG/ECG Technician",
    startYear: "2018",
    endYear: "2019",
  },
  {
    id: 8,
    company: "New Baps School",
    jobtitle: "ICT Tutor",
    startYear: "January 2021",
    endYear: "July 2021",
  },
  {
    id: 7,
    company: "Cambridge Remedial School",
    jobtitle: "Part-time Mathematics Tutor",
    startYear: "May, 2019",
    endYear: "July, 2019",
  },
  {
    id: 6,
    company: "Freelance",
    jobtitle: "Software Engineer - iOS",
    startYear: "October, 2020",
    endYear: "Present",
  },
  {
    id: 5,
    company: "Top Accountancy School",
    jobtitle: "Mathematics Tutor (Elective & Core)",
    startYear: "January, 2022",
    endYear: "September, 2022",
  },
  {
    id: 4,
    company: "Skideo.tv",
    jobtitle: "Mathematics Instructor",
    startYear: "March, 2021",
    endYear: "October, 2022",
  },
  {
    id: 3,
    company: "NEGE's Educational Consult",
    jobtitle: "Part-time Mathematics Tutor",
    startYear: "2017",
    endYear: "2023",
  },
  {
    id: 2,
    company: "HomiTV",
    jobtitle: "Software Engineer",
    startYear: "2023",
    endYear: "Present",
  },
  {
    id: 1,
    company: "Hubtel Limited",
    jobtitle: "Software Engineer",
    startYear: "July, 2022",
    endYear: "Present",
  }
];
