export const achievementData = {
  bio: "I don't believe that I have accomplished enough in my life to warrant having an entire section dedicated solely to my achievements.",
  achievements: [
    /*
    {
      id: 1,
      title: "HCIA - Routing and Switching ",
      details:
        "he HCIA-Routing & Switching covers basic IP network connectivity, TCP/IP technologies, Ethernet technologies such as STP and RSTP, VLAN and Link Aggregation and their implementation within Huawei switches.",
      date: "July 23, 2020",
      field: "Networking",
      image:
        "https://images.unsplash.com/photo-1555617766-c94804975da3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
    
    {
      id: 2,
      title: "",
      details: "",
      date: "",
      field: "",
      image: "",
    },
    {
      id: 3,
      title: "Professional Data Engineer Certification",
      details:
        "Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.",
      date: "Aug 20, 2019",
      field: "Automation",
      image:
        "https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    },
    */
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
