import resume from "../assets/pdf/Sidney_Okine_CV.pdf";
import image from "../assets/png/image-of-sidney-okine.png";
export const headerData = {
  name: "SIDNEY NII OKAI OKINE",
  title: "SOFTWARE ENGINEER  ••||•• TEACHER ",
  desciption:
    "Hi there, you're welcome to the website of Sidney Nii Okai Okine a software engineer with a passion for building scalable and efficient software. Feel free to take a walk in the park of greatness, I'm sure you'll find something that will interest you.",
  image:
    image,
  resumePdf: resume,
};
