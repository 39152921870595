import React from 'react';
import Lottie from 'react-lottie';
import loaderGif from './logo_gif.json';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderGif,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000
  };

  return (
    <div style={containerStyle}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default Loader;
