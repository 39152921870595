import chat from "../assets/svg/projects/chat.png";
import api from "../assets/svg/projects/api.png";
export const projectsData = [
  {
    id: 1,
    projectName: "A Simple Chat Application",
    projectDesc:
      "Created a fully functioning Mobile Application with authentication from Google Firebase",
    tags: ["Swift", "Swift UI", "Firebase"],
    code: "https://github.com/Sidney-Okine/Chat-Application-in-Swift-and-SwiftUI",
    demo: "https://github.com/Sidney-Okine/Chat-Application-in-Swift-and-SwiftUI",
    image: chat,
  },
  {
    id: 2,
    projectName: "Generic Networker iOS",
    projectDesc: "A Generic iOS network handler leveraging URLSession",
    tags: ["Swift", "Framework"],
    code: "https://github.com/Sidney-Okine/GenericNetworker",
    demo: "https://github.com/Sidney-Okine/GenericNetworker/blob/master/README.md",
    image: api,
  },
  /*
  {
    id: 3,
    projectName: "A Simple OTP Authentication App",
    projectDesc:
      "One-Time-Password Authentication system with the help of Firebase Auth Provider",
    tags: ["Swift", "SwiftUI", "Firebase"],
    code: "https://github.com/Sidney-Okine/User-Authentication-with-FirebaseAutho",
    demo: "https://github.com/Sidney-Okine/User-Authentication-with-FirebaseAuth",
    image: three,
  },
  {
    id: 4,
    projectName: "Coming Soon",
    projectDesc: "",
    tags: [""],
    code: "",
    demo: "",
    image: "",
  },
  {
    id: 5,
    projectName: "Coming Soon",
    projectDesc: "",
    tags: [""],
    code: "",
    demo: "",
    image: "",
  },
  {
    id: 6,
    projectName: "Coming Soon",
    projectDesc: "",
    tags: [""],
    code: "",
    demo: "",
    image: "",
  },
  {
    id: 7,
    projectName: "Coming Soon",
    projectDesc: "",
    tags: [""],
    code: "",
    demo: "",
    image: "",
  },
  {
    id: 8,
    projectName: "Coming Soon",
    projectDesc: "",
    tags: [""],
    code: "",
    demo: "",
    image: "",
  },
  */
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/