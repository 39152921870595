/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import {
  FaInternetExplorer,
  FaChalkboardTeacher,
  FaCameraRetro,
  FaPinterest,
  FaVideo,
  FaTabletAlt,
  FaRegNewspaper,
} from "react-icons/fa";

export const servicesData = [
  {
    id: 1,
    title: "iOS Software Development",
    icon: <BsCodeSlash />,
  },
  {
    id: 2,
    title: "Mathematics Tutoring",
    icon: <FaChalkboardTeacher />,
  },
  /*
  {
    id: 8,
    title: "Marketing",
    icon: <BiShoppingBag />,
  },
*/
  // {
  //     id: 4,
  //     title: 'Internet Research',
  //     icon: <FaInternetExplorer />
  // },
  // {
  //     id: 5,
  //     title: 'Tutoring',
  //     icon: <FaChalkboardTeacher />
  // },

  {
    id: 6,
    title: "Online Research",
    icon: <FaInternetExplorer />,
  },
  /*
  {
    id: 7,
    title: "Photography",
    icon: <FaCameraRetro />,
  },
  */
  // {
  //     id: 8,
  //     title: 'Pinterest Virtual Assistant',
  //     icon: <FaPinterest />
  // },
  /*  id: 9,
    title: "Video Editing",
    icon: <FaVideo />,
  },
  */
  // {
  //     id: 10,
  //     title: 'Data Entry',
  //     icon: <BsClipboardData />
  // },

  //{
  //id: 11,
  //title: "Ebook Writing",
  //icon: <FaTabletAlt />,
  //},
  // {
  //     id: 12,
  //     title: 'Audio Transcription',
  //     icon: <AiFillAudio />
  // },
  //{
  //id: 13,
  //title: "Blog Posting",
  //icon: <FaRegNewspaper />,
  //},
];

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
