export const educationData = [
  {
    id: 1,
    institution: "Tilitet Early Childhood Dev. Centre",
    course: "Nursery & Early DayCare",
    startYear: "2001",
    endYear: "2004",
  },
  {
    id: 2,
    institution: "New Hope School",
    course: "High School",
    startYear: "2004",
    endYear: "2009",
  },
  {
    id: 3,
    institution: "Arakan Junior High School",
    course: "High School",
    startYear: "2009",
    endYear: "2014",
  },
  {
    id: 4,
    institution: "St. Thomas Aquinas Senior High School",
    course: "Secondary Education",
    startYear: "2014",
    endYear: "2017",
  },
  {
    id: 5,
    institution: "University of Ghana",
    course: "BSc. Mathematics ",
    startYear: "2017",
    endYear: "2018",
  },
  {
    id: 6,
    institution: "University of Ghana",
    course: "BSc. Information Technology",
    startYear: "2018",
    endYear: "2022",
  }
];
