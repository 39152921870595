import React, { useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeContext } from "./contexts/ThemeContext";
import { Main, ProjectPage } from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";
import Loader from './components/Loader/Loader.js';

import "./App.css";

function App() {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); 
  }, []);

  console.log(
    "%cDEVELOPER PORTFOLIO",
    `color:${theme.primary}; font-size:50px`
  );
  console.log(
    "%chttps://github.com/Sidney-Okine",
    `color:${theme.tertiary}; font-size:20px`
  );

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        {loading ? ( 
          <Loader />
        ) : (
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/projects" exact component={ProjectPage} />
            <Redirect to="/" />
          </Switch>
        )}
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
